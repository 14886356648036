@import url("https://fonts.googleapis.com/css2?family=Golos+Text&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.uk-card > span {
  display: block;
}

.navbar_1 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_cont {
  width: 85%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-nav {
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.response_nav {
  display: none;
}
.form-select {
  border: none !important;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.navL {
  text-decoration: none;
 color: black;
}

.navL:hover {
  text-decoration: none;
 color: black;
}
.link_n {
  text-decoration: none !important;
  color: black !important;
  transition: 0.2s ease-in;
}
.link_n:hover {
  text-decoration: none;
  color: orangered !important;
}
.link_p_sevice{
  color: black !important;
}
.link_p_sevice:hover{
  color: orangered !important;
}
.menu {
  width: 25px;
  cursor: pointer;
}
.close {
  width: 15px;
}
a{
  text-decoration: none !important;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
  background-color: white !important;
  border: none !important;
}
@media screen and (max-width: 820px) {
  .nav_cont {
    display: none;
  }
  .navbar_1 {
    display: none;
  }
  .response_nav {
    padding: 15px;
    display: block;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .response_nav p {
    margin-bottom: 10px;
  }
  .response_nav .text_m {
    width: unset;
    height: unset;
    font-size: 14px;
  }
  .logoBalck {
    width: 70px;
  }
}
