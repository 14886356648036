* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grapihic_container {
  width: 100%;
  min-height: 100vh;
}

.cover_graphic_1 {
  width: 100%;
  min-height: 960px;
  background-image: url("../../assets/images/Rectangle\ 2\ \(5\).png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-attachment: fixed;
}

.text_graphic_box {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.titel_graphic_box {
  font-size: 40px;
  text-align: left;
  width: 80%;
}

.graphic_text_g {
  width: 80%;
  min-height: 100px;
}

.text_gr {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.our_graphic {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphic_text_box2 {
  width: 80%;
}

.gr_titiel {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.cover3_gr_image {
  width: 100%;
}
.cover3_gr {
  width: 100%;
  height: 700px;
  background-image: url(../../assets/images/Group\ 20.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction:column;
  justify-content: space-around;
}

.join_register_btn {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
}

.for_join_text {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titel_join {
  width: 80%;
  display: flex;
  align-items: center;
}

.text_join_btn_p {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.join_text_p {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.join_btn {
  width: 160px;
  height: 50px;
  background-color: #ff3600;
  cursor: pointer;
  border: none;
  color: white;
  font-size: 16px;
}

@media screen and (max-width: 780px) {
  .text_gr {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .join_text_p {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .main-titel_service {
    font-family: "Inter", sans-serif;
    font-size: 35px;
  }
  .for_join_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
.cover_graphic_1 {
  width: 100%;
  min-height: 960px;
  background-image: url("../../assets/images/Rectangle\ 2\ \(5\).png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-attachment: scroll; /* Standard syntax */
    
}

.titel_join {
  width: 90%;
  display: flex;
  align-items: center;
}

.titel_graphic_box {
  font-size: 40px;
  text-align: left;
  width: 90%;
}
}
