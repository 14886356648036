* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer_container {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.footer_box {
  width: 100%;
  height: 490px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.contact_text {
  text-align: center;
  color: white;
  font-size: 22px;
}
.contact_icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.contact_icon a {
  margin-left: 15px;
}
.logo {
  width: 80px;
  height: 80px;
  border: 2px solid #ff3600;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease-in-out;
}

.logo:hover{
   transform:rotate(360deg);
}

.date-of-creation {
  width: 210px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.data_p {
  color: white;
}

@media screen and (max-width: 780px) {
  .contact_icon {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .logo {
    width:35px;
    height:35px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s ease-in-out;
  }

  .contact_text p {
    font-size: 16px;
  }
}
