* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.scool-one-img {
  width: 100%;
}
.scool-page {
  width: 100%;
  height: 125vh;
  background-attachment: fixed;
  background-image: url(../../assets/images/School-page-COVER.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.titel_scool {
  width: 80%;
  display: flex;
  align-items: center;
}
.row-sc {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
  margin-bottom: 0;
}

.scool-text {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text-sectionOune {
  width: 80%;
}

.text-sectionTwo {
  width: 100%;
  height: 150px;
}

.rectangle {
  width: 100%;
  height: 170px;
}
.rectangle-0 {
  width: 100%;
  height: 170px;
}

.scoolImge {
  width: 100%;
  height: 125vh;
  background-image: url(../../assets/images/Untitled-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
 
}

.smm-scool {
  width: 100%;
  height: 125vh;
  background-image: url(../../assets/images/School-SMM-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.scool-uiux {
  width: 100%;
  height: 125vh;
  background-image: url(../../assets//images/Untitled-2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.scool-end {
  width: 100%;
  height: 125vh;
  background-image: url(../../assets/images/School-WEB.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  
}


@media (max-width: 767px) {
  .row-sc {
    font-size: 14px;
    line-height: 26px;
  }
  .scool-page {
    width: 100%;
    height: 100vh;
    background-attachment: scroll;
    background-image: url(../../assets/images/School-page-COVER.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: calc(30%);
  }
.scoolImge {
  width: 100%;
  height: 40vh;
  background-image: url(../../assets/images/Untitled-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: scroll;
 
}

.smm-scool {
  width: 100%;
  height: 40vh;
  background-image: url(../../assets/images/School-SMM-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: scroll;
}

.scool-uiux {
  width: 100%;
  height: 40vh;
  background-image: url(../../assets//images/Untitled-2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: scroll;
}

.scool-end {
  width: 100%;
  height: 40vh;
  background-image: url(../../assets/images/School-WEB.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: scroll;
  
}
.rectangle-0 {
  width: 100%;
  height: 80px;
}
.uk-modal-dialog {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100% !important;
  max-width: 100%!important;
  background: #fff;
  opacity: 0;
  transform: translateY(-100px);
  transition: .3s linear;
  transition-property: opacity,transform;
}
.rectangle {
  width: 100%;
  height: 80px;
}
}