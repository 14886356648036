* {
  margin: 0;
  padding: 0;
}
.work-imges{
  cursor: pointer;
}
.ourTime {
  width: 100%;
  height: 900px;
  background-color: #e9e8e9;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column !important;
  flex-flow: column !important;
  overflow-y: scroll !important;
  height: 100vh !important;
}
.ReactModal__Content.ReactModal__Content--after-open{
  overflow: hidden !important;
}
.ourPr_title {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  justify-content: center;
}

.titele_text {
  font-size: 40px;
  font-family: sans-serif;
  margin: 0;
}
   .clos_icon_container{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
   }
.clubOf {
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.iriknak {
  width: 100% !important;
  min-height: 350vh !important;
}
  .irinik_section{
    width: 75%;
    margin: 0 auto;
  }
.toClos {
  cursor: pointer;
  width: 40px;
}

.our_pr{
  width: 40%;
   margin: 0 auto;
}

.avanta_section{
  width: 35%;
  margin: 0 auto;
}


.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  max-width: 1250px !important;
  transition: height .15s ease-in;
}



button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  background-color: #3498db;
  color: white;
  border-radius: 3px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

   .pagition_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: fixed;
}
.MuiPagination-ul li:nth-child(2){
  display: none !important;
}


.MuiPagination-ul li:nth-child(3){
  display: none !important;
}

.MuiPagination-ul li:nth-child(4){
  display: none !important;
}

.MuiPagination-ul li:nth-child(5){
  display: none !important;
}

.MuiPagination-ul{
  width: 100% !important;
   display: flex !important;
   justify-content: space-between !important;
}

.control-arrow .control-prev{
  display: none !important;

}

.control-arrow .control-next{
  display: none !important;
}

.carousel .control-arrow:focus, .carousel .control-arrow:nth-child(1){
  display: none !important;

}


.carousel .control-arrow:focus, .carousel .control-arrow:nth-child(3){
  display: none !important;

}

.css-1oj2twp-MuiPagination-root {
  width: 100% !important;
  display: flex !important;
  justify-content: space-around !important;
}

.css-n8417t-MuiSvgIcon-root-MuiPaginationItem-icon {
 
  font-size: 3.375rem !important;
}

.carousel .control-dots {
  bottom: 0;
  display: none !important;
}

.text-or-btn {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;

  justify-content: space-between;
  background-color: white;
  
}
@media screen and (max-width:768px) {
  .titele_text {
    font-size: 35px;
    font-family: sans-serif;
    margin: 0;
}
.carousel.carousel-slider .control-arrow {
   display:none !important;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column !important;
  flex-flow: column !important;
  overflow-y: scroll !important;
  height: 100vh !important;
}
}