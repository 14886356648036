* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.grid-list div.uk-card-body {
  margin: 0 !important;
  height: 100%;
}

.container_uiux {
  width: 100%;
  min-height: 100vh;
}
.cover1_container {
  width: 100%;
}

.uiux_cover1 {
  width: 100%;
  height: 170px;
  background-image: url(../../assets/images/ui-ux2.png);
  background-size: contain;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.text-uiux1-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.titel_h2_ui {
  width: 80%;
  display: flex;
  align-items: center;
}

.titel_ui1 {
  color: black;
  font-size: 40px;
  font-family: "Inter", sans-serif;
  line-height: 52px;
}

.ui_text1_container {
  width: 80%;
}

.ui_text1 {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
  margin-bottom: 0;
  padding-bottom: 30px;
}

.ui-ux-container2 {
  width: 100%;
  min-height: 600px;
}

.uiux-cover-container2 {
  width: 100%;
  height: 1500px;
  background-image: url(../../assets/images/samantha-gades-gKjLLN1T8HQ-unsplash.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.ui-ux-text-container2 {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.Why-Study-With-Us-T {
  width: 80%;
  display: flex;
  align-items: center;
}

.tite_2_ux {
  color: black;
  font-size: 40px;
  font-family: "Inter", sans-serif;
  line-height: 52px;
}

.our_curse_ux {
  width: 80%;
}

.our_curse_ux ul {
  margin-bottom: 0;
}

.trainer_container_ux {
  width: 100%;
  min-height: 800px;
  display: flex;
  justify-content: space-evenly;
}

.our_trainer_text_ux {
  width: 50%;
  min-height: 800px;
}

.curse_text {
  width: 70%;
  height: 800px;
  display: flex;
  flex-wrap: wrap;
}

.list_text {
  width: 200px;
  height: auto;
  display: flex;
}

  
.ux-prog_container{
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
}
.our_trainer_text_ui{
  width: 100%;
    height: 92%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: white;
}
.ux_ul_li{
  width: 80%;
}
.titel_t_o{
    width: 80%;
   
    display: flex;
    align-items: center;
}
.list_disk_not{
  list-style: none;
}
.trainer_ui {
  width: 410px;
  display: flex;
  flex-direction: column;
  min-height: auto;
  padding-top: 20px;
}

.ux-container-curse{
  width: 100%;
  min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.curse_container_2{
  width: 80%;
  min-height: 100vh;
 
}
.titel_ux_curse{
  width: 100%;
  min-height:100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.curset_txt_contin{
 grid-gap: 15px;
 display: grid;
 grid-template-columns: repeat(1,1fr);
 margin-top: 30px;
 padding-left: 15px;
 padding-right: 15px;
}
.gris-list{
  height: 100%;
  margin: 0 !important;
  background-color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
}
.gris-list:hover{
  background-color: #fff;
  box-shadow: 0 14px 25px rgba(0,0,0,.16);
}
.curse_plan{
  padding: 20px;
}
.curse_name_1{
  padding: 25px;
}

@media screen and (max-width: 780px) and (-webkit-min-device-pixel-ratio: 2) {
 
  .cover1_container {
    width: 100%;
    height: 90px;
  }
  .list_wb {
    font-size: 14px;
    line-height: 26px;
  }
  .uiux_cover1 {
    width: 100%;
    height: 260px;
    background-image: url(../../assets/images/ui-ux2.png);
    background-size: contain;
    background-attachment: scroll; /* Standard syntax */
    
  }
  .titel_ui1 {
    color: black;
    font-size: 35px;
    font-family: "Inter", sans-serif;
    line-height: 52px;
  }
  .tite_2_ux {
    color: black;
    font-size: 35px;
    font-family: "Inter", sans-serif;
    line-height: 52px;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-5 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .our_trainer_text_ui {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
}
.uiux-cover-container2 {
  width: 100%;
  min-height: 240vh;
  background-image: url(../../assets/images/samantha-gades-gKjLLN1T8HQ-unsplash.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 70%;
  background-attachment: scroll; /* Standard syntax */
    
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
  .ui_text1 {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
}


@media (max-width: 575px) {
  
  .trainer_ui {
    max-width: 100%;
    word-break: break-word;
  }

  .profession_trainer {
    display: grid;
  }

  .titel_ux_curse h2 {
    text-align: center;
  }

  .our_trainer_text_ui {
    height: 100% !important;
  }
}

@media(max-width: 818px) {
  .our_trainer_text_ui {
    height: 72%;
  }
  .grid-list {
    grid-gap: 15px;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
}


}
