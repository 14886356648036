* {
  margin: 0;
  padding: 0;
}

.book-design {
  width: 100%;
  height: 60vh;
  background-image: url("../../assets/images/bookdesigncover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-attachment: fixed;
    background-position: center;
}
.book_cover_mobile{
  display: none;
}
.imges-section {
  width: 100%;
  height: 100vh;
}
.graphic-text {
  font-size: 40px;
  font-family: "Inter", sans-serif;
}
.text-p_b {
  width: 80%;
  min-height: 130px;
}
  
.imges-section {
  width: 100%;
  background-image: url(../../assets/images/photo1691391504.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  flex-direction: column;
  justify-content: space-around;
}
.row-b {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.about-books {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.book {
  margin-top: 20px;
}
.book-l {
  width: 80%;
  display: flex;
  align-items: center;
}
.icon-text-book {
  width: 80%;
  display: flex;
  align-items: center;
  font-size: 40px;
}

.book-text {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.book-info {
  font-size: 40px;
  margin-bottom: 15px;
}

.imgesook {
  width: 100%;
}

.bookdes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.book_p_t {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

@media screen and (max-width: 780px) {
  .graphic-text {
    font-size: 35px;
    font-family: "Inter", sans-serif;
  }
  .row-b {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .book_p_t {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  
.imges-section {
  width: 100%;
  background-image: url(../../assets/images/photo1691391504.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-attachment: scroll; /* Standard syntax */
  
  min-height: 140vh;
}
.book_cover_mobile{
  display: block;
}
.book-design{
  display: none;
} 
}

