*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wiewcareers-container{
    width: 100%;
    height: 300px;
    background-color: red;
}


.form-control:focus {
    /* color: var(--bs-body-color); */
    /* background-color: var(--bs-body-bg); */
    border-color: #86b7fe;
    /* outline: 0; */
     box-shadow: 0 0 0 -0.75rem  !important;
}