*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.fleg_container{
    width: 80px;
    display: flex;
    justify-content: space-between;
}
.flagA{
    cursor: pointer;
}
.flagE{
    cursor: pointer;
}
@media screen and (max-width:780px) {
     
.fleg_container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}
.flagA{
    margin-right: 10px;
}
}