*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.uk-modal-dialog {
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;
    width: 70% !important;
    max-width: 100%!important;
    background: #fff;
    opacity: 0;
    transform: translateY(-100px);
    transition: .3s linear;
    transition-property: opacity,transform;
}

textarea::placeholder{ 
  color: black !important;
}
.cotact_us_form_join{
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.titel_reg_contact_join{
     width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_join{
    width: 170px;
    height: 45px;
    background-color: orangered;
    color: white;
    padding: 0;
    transition: 3s all ease;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_join:hover .animation_div{
    width: 330px !important;
    height: 330px !important;
}

.animation_div{
    transition: 0.5s;
    position: relative;
  
  }
.btn_join span  {
   position: relative;
   z-index: 1 ;
}
.btn-primary-join{
    width: 200px;
    height: 45px;
    background-color: orangered !important;
    border-radius: 10px;
    color: white !important;
}

.btn-primary-join:hover{
    width: 200px;

    background-color: green !important;
    
    color: white !important;
    opacity: 0.7;
}

  @media screen and  (max-width:768px) {
     
    .cont_user-name_lastname_reg {
        width: 100% !important;
        display: grid;
        justify-content: space-around;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        margin-top: 15px !important;
    
    
    }
  }