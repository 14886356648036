.container-webDev {
  width: 100%;
  height: 110vh;
  background-image: url("../../assets/images/Rectangle\ 3\ \(7\).png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.container-webDev-text {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.webDes-titel {
  font-size: 40px;
  font-family: sans-serif;
}

.img-text-cont-webDev {
  width: 80%;
  display: flex;
  align-items: center;
}

.languages {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dev-imges {
  width: 100%;
  height: auto;
}
.about-dev {
  font-size: 40px;
  color: black;
  font-family: "Inter", sans-serif;
}
.webdev {
  width: 100%;
}

.text-webdev {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dev {
  width: 100%;
}

.row-dev {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.container_text_ux_ {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 780px) {
  .about-dev {
    font-size: 35px;
    color: black;
    font-family: "Inter", sans-serif;
  }
  .row-dev {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .container-webDev {
    width: 100%;
    height: 110vh;
    background-image: url("../../assets/images/Rectangle\ 3\ \(7\).png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll; /* Standard syntax */
  
    
  }
}
