* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a:hover{
  color: white;
}
.graphic_curs_container {
  width: 100%;
  min-height: 100vh;
}

.cover_grCurse_1_container {
  width: 100%;
}

.cover_grCurse_1 {
  width: 100%;
  height: 300px;
  background-image: url(../../assets/images/Rectangle\ 44.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.graphic_curse_text_container {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tiitel_container {
  width: 80%;
  display: flex;
  align-items: center;
}

.graphic_c_text_1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.gr_c_p {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.gr_c_text_images_container {
  width: 100%;
  min-height: 100vh;
}

.gr_c_imges {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/images/minh-pham-lB9ylP8e9Sg-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.gr_c_titel2 {
  width: 80%;
  display: flex;
  align-items: center;
}
.gr_c_text_container2 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gr_c_text_cont2 {
  width: 80%;
}

.dat {
  width: 20px;
  height: 20px;
}

.list_gr_c {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.our_trainer_container {
  width: 100%;
  min-height: 120vh;
  display: flex;
  justify-content: center;
}

.trainer_images_text {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.trainer {
  width: 410px;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
}

.trainerImages {
  min-width: 502px;
}

.trainer_name {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
}

.profession_trainer {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  display: flex;
}

.our_trainer_text {
  width: 600px;
}

.titel_t_o {
  width: 100%;
}

.who-will-be-trainer {
  font-size: 40px;
  font-family: "Inter", sans-serif;
}
ol, ul {
  padding-left: 0rem !important;
}
.studets_works {
  width: 100%;
}

.work_titel_gr_c {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

.work_image {
  width: 100%;
}
.btn_container_gr_curse {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our-work-btn {
  width: 170px;
  height: 45px;
  background-color: black;
  color: white;
  padding: 0;
  transition: 3s all ease;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.our-work-btn span {
  position: relative;
  z-index: 1;
}
   
.stwork {
  text-decoration: none;
  color: white;
}


@media screen and (max-width: 820px) {
  
.cover_grCurse_1 {
  width: 100%;
  height: 300px;
  background-image: url(../../assets/images/Rectangle\ 44.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.cover_grCurse_1_container {
  width: 100%;
  height: 100%;
  max-height: 100px;
}
  .gr_c_text_images_container {
    width: 100%;
  }
  .gr_c_p {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
    margin-left: 20px;
  }
  .gr_c_p + p {
    margin-left: 20px;
    font-size: 14px;
    line-height: 26px;
  }
  .main-titel_service {
    font-family: "Inter", sans-serif;
    font-size: 25px;
    font-weight: bold;
    padding: 20px;
  }
  
  .graphic_curse_text_container {
    align-items: flex-start;
  }
  .list_gr_c {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .trainerImages {
    min-width: 280px;
  }
  .trainer_name {
    font-family: "Inter", sans-serif;
    font-weight: bold;
    font-size: 14px;
  }
  .profession_trainer {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .trainer {
    width: 410px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text_block_trainer{
    padding: 30px;
  }
  .who-will-be-trainer {
    font-size: 26px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
}
  .titel_t_o {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
 
  .work_image {
    width: 100%;
    
  }
  .studets_works {
    width: 100%;
  }
  .gr_c_titel2 {
    width: 80%;
    display: flex;
    align-items: center;
    
  }
  .work_titel_gr_c {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .register .titele_r {
    font-size: 25px !important;
  }

  .reg_n {
    font-size: 15px;
  }

  .cont_user-name_lastname {
    grid-template-columns: 1fr !important;
  }

  .our_trainer_text {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
    margin-left: 20px
  }

  .trainer_images_text {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .gr_c_text_cont2 {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
    margin-left: 20px
  }
  .graphic_c_text_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .gr_c_text_container2 {
    align-items: start;
  }
  
.gr_c_imges {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/images/minh-pham-lB9ylP8e9Sg-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-attachment: scroll; 
}
}




.animation_div{
  transition: 0.5s;
  position: relative;

}

.our-work-btn:hover .animation_div{
    width: 300px !important;
    height: 300px !important;
}