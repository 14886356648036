* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hello_worlds {
  background-color: #e9e8e9;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
 
}
.work-imges {
  width: 100%;
  margin-top: 50px;
}

.bac-btn {
  width: 100%;
  height: 100px;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 40px !important;
  width: 60px;
}
.back {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  margin-top: 30px;
}
.icon-back {
  width: 14px;
  height: 14px;
}

.caruselNew {
  width: 100%;
  height: 100vh;
}

.lumins_img {
  width: 100%;
  height: 750px;
}

.thumb {
  display: none !important;
}

.control-arrow {
  background-color: black !important;
  height: 30px;
  position: fixed !important;
  display: flex;
  align-items: center;
  border-radius: 50%;
  top: 50% !important;
}


.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
  .ReactModal__Overlay.ReactModal__Overlay--after-open{
    position: fixed;
    inset: 0px;
    background-color: rgb(0 0 0 / 75%) !important;
  }
  .ReactModal__Content.ReactModal__Content--after-open{
    background: transparent !important;
    border: none !important;
    width: 80% !important;
  }
  .clos_btn_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }


.title {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-left: 15px;
}

.close_path {
  width: 70px;
  height: 30px;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in;

}
.close_path:hover{
  color: rgba(250, 66, 66, 0.9);
}
.carousel .carousel-status {
  display: none !important;
}
.work-L {
  font-size: 23px;
  font-family: sans-serif;
}
.name_work {
  font-size: 16px;
}
.workImges-cont {
  display: flex;
  justify-content: center;
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 51%;
  transition:  0.15s ease-in;
}



.title-l {
  width: 600px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-left: 15px;
}
.container-l {
  width: 100%;
  background-color: rgb(0 0 0 / 90%);
}

.to_close {
  cursor: pointer;
}
@media (max-width: 767px) {
  .title {
    width: 100%;
   
  }
  .ReactModal__Content.ReactModal__Content--after-open{
    background: transparent !important;
    border: none !important;
    width: 100% !important;
  }
}