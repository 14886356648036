* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem !important;
}

.register_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.error-info {
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  padding: 0;
  margin: 0;
  right: 0px;
  font-size: 14px;
  color: #FB4242;
  bottom: -20px;
}

.mail_inline {
  display: flex;
  position: relative;
}

.mail_inline .input-group-text {
  border-right: none;
  border-radius: 0.375rem 0 0 0.375rem;
}

.mail_inline input {
  border-radius: 0 0.375rem 0.375rem 0;
}

.register_sellect {
  padding: 0;
  margin: 0;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 45px;
  border: 2px solid !important;
  box-shadow: none !important;
}

.css-13cymwt-control>div,
.css-t3ipsp-control>div {
  height: 100% !important;
  display: flex;
  align-items: center;
  flex-wrap: unset !important;
}


.has-error input,
.has-error .textarea_input,
.has-error .css-13cymwt-control,
.has-error .css-t3ipsp-control {
  border: 2px solid #FB4242 !important;
}

.has-error .input-group-text {
  border: 2px solid #FB4242 !important;
  border-right: none !important;
}

.textarea_input {
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
}

.register_sellect input {
  opacity: 0;
  color: transparent;
  border: none !important;
}

.has-error .error-info {
  opacity: 1;
}


.form-floating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-select {
  border: 2px solid !important;
  background-size: 18px 24px !important;
}

.form-block {
  width: 100%;
  position: relative;
}

.form-floating {
  align-items: center;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  width: 82% !important;
  margin-top: 15px !important;
}

.register .btn {
  margin-top: 15px;
}

.text_area {
  grid-column-start: 1;
  grid-column-end: 3;
}

.register {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register .input-group {
  flex-wrap: nowrap;
}

.register_container .textarea_floating {
  grid-template-columns: 1fr;
  padding-top: 10px;
}

.register_container .textarea_floating .form-label {
  position: relative;
  top: auto;
  height: auto;
  min-height: auto;
  margin: 0;
  padding: 0;
  margin-bottom: -25px;
}

.form-label {
  margin-bottom: 0.5rem;
  display: block;
}

.register_container .textarea_floating textarea {
  min-height: 100px;
  position: relative;
}

.textarea_floating .form-label {
  text-transform: uppercase;
  top: -10px;
}

.register .form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cont_user-name_lastname {
  width: 82% !important;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 15px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 45px;
  border: 2px solid !important;
}

.register {
  max-width: 1250px;
  background-color: #f9f9f9;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.register .titele_r {
  font-size: 27px;
}

.sellect_section {
  position: relative;
}

.col-12.buton_register {
  padding: 15px;
  margin-top: 20px;
}

.form-floating>label {
  z-index: 0 !important;
}

.reg_titel_container {
  margin-top: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-primary {
  background-color: #ff3600 !important;
  border: none !important;
  border-radius: 0 !important;
  width: 150px;
}

.titel_register {
  width: 82%;

}

.btn_join {
  padding: 10px;
}

.t_h1 {
  color: #ff3600;
}

.reg_n {
  color: #13a410;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 5rem 0.75rem;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  border: 2px solid;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: calc(-1 * var(--bs-gutter-y)); */
  margin-top: 0 !important;
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

@media screen and (max-width: 900px) {
  .register {
    width: 100%;
    min-height: 440px;
    background-color: #f9f9f9;
    border-radius: 20px;
  }

  .form-label {
    display: none;
  }

  .titel_register {
    width: 100%;
    min-height: 150px;
  }

  .titele_r {
    font-size: 20px;
  }

  .cont_user-name_lastname_reg {
    width: 100% !important;
    display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-top: 15px !important;
  }

  .form-floating>.form-control,
  .form-floating>.form-control-plaintext {
    padding: 10px !important;
  }

}

@media screen and (max-width:768px) {
  .cont_user-name_lastname {
    width: 100% !important;
    display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-top: 15px !important;
  }

  .form-floating {
    align-items: center;
    display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    width: 100% !important;
    margin-top: 15px !important;
  }

  .form-block,
  .form_block {
    margin-bottom: 10px;

  }
}

.alert_container {
  width: 100%;
  display: flex;
  max-width: 1250px;
  align-items: center;
  transition: 0.5s;
  opacity: 0;
  padding-top: 30px;
}

.alert_container.show {
  opacity: 1;
}

.alert-success {
  width: 100%;
  color: white;
  background-color: #4fd69c;
  border-color: #4fd69c;
  display: flex;
  align-items: center;
}

.alert-dismissible .close {
  top: 47%;
  position: absolute;
  right: 1.5rem;
  padding: 0;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  background: transparent;
  opacity: 1;
  right: 1rem;
}

.alert-inner--icon {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.alert-inner--icon svg {
  font-size: 20px;
}

.alert-dismissible .close:hover,
.alert-dismissible .close:focus {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1 !important;
}

.alert-dismissible .close>span:not(.sr-only) {
  font-size: 2rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.alert-dismissible .close:hover>span:not(.sr-only),
.alert-dismissible .close:focus>span:not(.sr-only) {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}