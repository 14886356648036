* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.web-des {
  width: 100%;
  height: 110vh;
  background-image: url("../../assets/images/Web-design-.-templates\ \(1\).png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.webdes-text {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-text {
  width: 80%;
  display: flex;
  align-items: center;
}
.text-webdev {
  width: 100%;
  min-height: 180px;
  display: flex;
  justify-content: center;
}
.webDes-titel {
  font-size: 50px;
}
.web-laptop {
  width: 100%;
}

.laptop {
  width: 100%;
}

.ui-text {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.row-wd {
  font-size: 20px;
  line-height: 38px;
  font-family: "Inter", sans-serif;
}

.web_des_text_container {
  width: 80%;
  min-height: 160px;
}

.des_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.des_text > p {
  width: 80%;
  font-size: 20px;
  line-height: 38px;
}

.line-black{
  width: 100%;
   border: 40px solid black;
}

@media screen and (max-width: 890px) {
  .web_des_text_container {
    width: 80%;
    min-height: 160px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-text {
    width: 80%;
    display: flex;
    align-items: center;
  }
  .des_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .row-wd, .des_text > p {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .footer_container {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .web-laptop {
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .webDes-titel {
    font-size: 35px;
    font-family: "Inter", sans-serif;
  }
  
.web-des {
  width: 100%;
  height: 110vh;
  background-image: url("../../assets/images/Web-design-.-templates\ \(1\).png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll; /* Standard syntax */
    
}
}
