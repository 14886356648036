@import url("https://fonts.googleapis.com/css2?family=Golos+Text&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gallery-contaner {
  width: 100%;
  min-height: 100vh;
}

.about_us_container {
  width: 100%;
  min-height: 100vh;
}

.cover_about_n1 {
  width: 100%;
  height: 161px;
  background-image: url(../../assets/images/Rectangle\ 14.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.cover_c {
  width: 100%;
  height: 160px;
}

.about_text_c {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.about_text {
  width: 80%;
}

.text_g {
  font-size: 26px;
  color: black;
  font-family: "Inter", sans-serif;
}

.titel_a {
  font-size: 40px !important;
  font-family: "Inter", sans-serif !important;
  color: black !important ;
}
.text_s_ab {
  font-family: "Inter", sans-serif;
  line-height: 38px;
  font-size: 20px;
}

.about_cover_c2 {
  width: 100%;
  min-height: 100vh;
}

.cover1_service {
  width: 100%;
  height: 200px;
 
}

.imgesServicCover {
  width: 100%;
  height: 200px;
  background-image: url(../../assets/images/Rectangle\ 7.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.images_s_1 {
  width: 300px;
  height: 400px;
  cursor: pointer;
}
.color_hover{
  background-color: rgba(0,0, 0, 0.7) !important;;
}
.hover_p{
  color: white !important;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.servicImages_container {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.service_titel {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-titel_service {
  font-family: "Inter", sans-serif;
  font-size: 40px;
}

.images_s_c {
  max-width: 1086px;
  
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.text_s {
  width: 100%;
  height: auto;
  text-align: center;
}

.imagesS1 {
  width: 300px;
  height: 400px;
  object-fit: cover;
}

.sevice_text_titel {
  font-family: "Inter", sans-serif;
  font-size: 24px;
}
.service_cover_n3 {
  width: 100%;
  height: 200px;
}

.new_progects_container {
  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.new-progect-iages-cont {
  max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
 
   
}

.newProjectImg {
  width: 30%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.serviceCover3 {
  width: 100%;
  height: 200px;
  background-image: url("../../assets/images/Rectangle\ 3\ \(3\).png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; /* Standard syntax */
    -webkit-background-attachment: fixed; /* Webkit prefix */
    -moz-background-attachment: fixed; /* Mozilla prefix */
    -o-background-attachment: fixed; /* Opera prefix */
    -ms-background-attachment: fixed; /* Microsoft prefix */
}

.hover12 figure img {
	opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover12 figure:hover img {
	opacity: .5;
}
@media screen and (max-width: 768px) {
  
.images_s_c {
  max-width: 1086px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
}

.uk-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  padding: 60px 15px !important;
  background: rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity .15s linear;
}
  .titel_a {
    font-size: 35px !important;
    font-family: "Inter", sans-serif !important;
    color: black !important;
  }
  .newProjectsImg1 {
    width: 300px;
  }
  .newProjectImg {
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
 
  }
  .text_s_ab {
    font-family: "Inter", sans-serif;
    line-height: 26px;
    font-size: 14px;
  }
  .cover_about_n1 {
    width: 100%;
    height: 161px;
    background-image: url(../../assets/images/Rectangle\ 14.png);
    background-attachment: scroll; /* Standard syntax */
    background-repeat: no-repeat;
    background-size: cover;
}
.imgesServicCover {
  width: 100%;
  height: 200px;
  background-attachment: scroll; /* Standard syntax */
  background-image: url(../../assets/images/Rectangle\ 7.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
}

.serviceCover3 {
  width: 100%;
  height: 200px;
  background-image: url("../../assets/images/Rectangle\ 3\ \(3\).png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll; /* Standard syntax */

}
.uk-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  padding: 30px 60px;
  background: rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity .15s linear;
   display: none;
}
}
