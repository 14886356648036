* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container_cover_webcurse {
    width: 100%;
    min-height: 100vh;
}

.web_curse-cover1 {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/images/alexandru-acea--WBYxmW4yuw-unsplash.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
}

.web-curse-text1 {
    width: 100%;
    background-color: white;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titel-container-wb {
    width: 80%;
    display: flex;
    align-items: center;
}

.titel_wb {
    font-size: 40px;
    font-family: "Inter", sans-serif;
    color: black;
}

.text-webi {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text_container_pvb1 {
    width: 80%;
}

.row-wb1 {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    color: black;
    line-height: 38px;
    margin-bottom: 0;
}

.Why_study_with_us_wb {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titel_study {
    width: 80%;
    display: flex;
    align-items: center;
}

.wb-stud {
    width: 80%;
}

.text_2_wb p {
    margin-bottom: 0;
}

.grid-list h3 {
    font-size: 24px;
}

.list_wb {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    color: black;
    line-height: 38px;
}

.text_2_wb {
    width: 80%;
}

.curseTitel {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-web-cont {
    width: 100%;
    height: 501px;
}

.curse-program-conainer {
    width: 100%;
    min-height: 80vh;
}

.titel {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.container-wev-dev {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.code {
    width: 100%;
}

.programCurse {
    width: 800px;
    height: 1000px;
}

.text-sec-2 {
    width: 80%;
    height: 214px;
    display: flex;
}

.icon-ka {
    width: 100px;
    height: 40px;
}

.curse-text-t {
    font-size: 18px;
    text-align: center;
}

.curse_i {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}


.curse-pr {
    width: 100%;
}

@media screen and (max-width: 780px) {
    .titel_wb {
        font-size: 35px;
        font-family: "Inter", sans-serif;
        color: black;
    }

    .row-wb1 {
        font-size: 14px;
        font-family: "Inter", sans-serif;
        color: black;
        line-height: 26px;
    }
    .grid-list {
        grid-gap: 15px;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        margin-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
.web_curse-cover1 {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/images/alexandru-acea--WBYxmW4yuw-unsplash.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    display: flex;
    align-items: center;
}
}
