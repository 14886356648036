* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.imge-container {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/images/SMM-page-cover.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

}

.img-text-cont-scool {
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.smm-text {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.smm-title {
  font-size: 40px;
  font-family: sans-serif;
}

.icon-text-smm {
  width: 80%;
  display: flex;
  align-items: center;
}

.text-p {
  width: 80%;
}

.icon-link {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.container-icon {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}

.row-sm {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.smm-images-c {
  width: 100%;
  min-height: 100vh;
  background-image: url(../../assets/images/hannah-busing-JQ7yAefHV14-unsplash.jpg);
  background-size: cover;
  background-repeat:no-repeat;
  background-attachment: fixed; /* Standard syntax */
  background-position: center;
   display: flex;
   align-items: center;
}

@media screen and (max-width: 780px) {
  .smm-title {
    font-size: 30px;
    font-family: sans-serif;
  }

  .imge-container {
    height: 40vh;
    background-position: unset;
    background-attachment: unset;
    background-size: contain;
  }

  .row-sm {
    font-size: 14px;
    line-height: 26px;
  }
}
