* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contac-us-container {
  width: 100%;
  min-height: auto;
  background-image: url("../../assets//images/Rectangle\ 2\ \(6\).png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box_container_c {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.contact-box {
  width: 70%;
  display: flex;
  justify-content: flex-end;
}

.contact-item {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.titel_contact_us {
  width: 100%;
}

.titel_contact {
  color: black;
  font-size: 50px;
  font-family: "Inter", sans-serif;
}

.icon_sayt {
  width: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.icon_sayt a {
  margin-top: 15px;
}

.cotact_us_form {
  margin-top: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.titel_reg_contact {
  width: 82%;
  display: flex;
  align-items: center;
}

.cont_user-name_lastname_reg {
  width: 82% !important;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 15px !important;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 5rem 0.75rem;
}

.btnReg {
  height: 100px;
  display: flex;
  align-items: center;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  border: 2px solid;
}

.icon_images {
  width: 70px;
}



@media screen and  (max-width:768px) {
     
  .cont_user-name_lastname_reg {
      width: 100% !important;
      display: grid;
      justify-content: space-around;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      margin-top: 15px !important;
  
  
  }
  .icon_sayt {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}