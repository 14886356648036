* {
  margin: 0;
  padding: 0;
}

.appdesImges {
  width: 100%;
  height: 250vh;
  background-image: url("../../assets/images/Rectangle\ 2\ \(8\).png");
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.appdes {
  width: 100%;
  height: 600px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apptext_1 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title__ {
  width: 80%;
  display: flex;
  align-items: center;
}

.titel_01 {
  color: white;
  font-size: 40px;
}

.text-app {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row-ap {
  color: white;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.app-text2-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 15px;
}

.app-titel-2 {
  width: 80%;
  display: flex;
  align-items: center;
}

.titel_app {
  width: 100%;
}

.our-service-app {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-app-2 {
  color: black;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 38px;
}

.app_t_l {
  font-size: 40px;
  font-family: "Inter", sans-serif;
  color: black;
}

.imges-app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 780px) {
  .titel_01 {
    font-family: "Inter", sans-serif;
    color: white;
    font-size: 35px;
  }
  .row-ap {
    color: white;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .app_t_l {
    font-size: 23px;
    font-family: "Inter", sans-serif;
    line-height: 42px;
    color: black;
  }
  .row-app-2 {
    color: black;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 26px;
  }
  .appdesImges {
    width: 100%;
    height: 250vh;
    background-image: url("../../assets/images/Rectangle\ 2\ \(8\).png");
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: scroll; /* Standard syntax */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    background-attachment: fixed;
}
}
