@import url("https://fonts.googleapis.com/css2?family=Golos+Text&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container_home {
  width: 100%;
  min-height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pointer{
  cursor: pointer;
}

.navbar_1 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_cont {
  width: 85%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-nav {
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.link_l {
  color: white !important;
  text-decoration: none;
}
.link_n_h{
  color: black !important;
  transition: 0.2s ease-in;
}
.link_n_h:hover{
  color: orangered !important;
}
.language_transletion {
  width: 100px;
  height: 20px;
}

.logo_container_images {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
}

.cover-home {
  max-width: 100%;
  background-image: url(../../assets/images/Frame\ 15.png);
  width: 600px;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  transition: 1s ease-in;
  animation: identifier 9s infinite ease-in;
  transition: 1s ease-in;
}

.home_menu {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_text {
  width: 80%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.text_m {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  width: 230px;
  height: 50px;

 
}
li{
  list-style: none;
}
.elipse_cont {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.elipse_cont-graphic{
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.elipse_cont-smm{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.elipse {
  display: none;
}
.text_m:hover {

  text-decoration: none;
  color: white;
}
.text_m:hover .elipse {
  display: block;
  animation: bounce 1s;
}
.link_p_sevice {
  width: 100%;
  transition: 0.2s ease-in-out;
}

.link_container_service a:last-child .link_p_sevice {
  margin-bottom: 0;
}
.menu-nav > .link_l {
  margin-bottom: 0;
}
.uk-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px !important;
  margin: 30px;
}
.card-hover{
  background: linear-gradient(135deg, rgba(238, 238, 238),rgb(216, 215, 215,0.3)) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter:blur(10px) !important ;
  border-radius: 20px !important; 
text-align: center !important;
width: 200px !important;
}
.navL_h{
  color: black !important;
  text-align: center !important;
  transition: 0.2s ease-in;
}
.navL_h:hover{
  color: orangered !important;
}
@keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes identifier {
  0% {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 16.png);
    width: 600px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(1.01);
  }

  25% {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 17.png);
    width: 600px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    z-index: 999;
    transform: scale(1.05);
  }

  50% {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 18.png);
    width: 600px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    z-index: 999;
    transform: scale(1.06);
  }
}
.response_nav_home {
  display: none;
}

@media screen and (max-width: 820px) {
  .response_nav_home {
    padding: 15px;
    display: block;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-color: black;
  }
  .response_nav_home p {
    margin-bottom: 10px;
  }
  .response_nav_home .text_m {
    width: unset;
    height: unset;
    font-size: 14px;
  }
  .link_container {
    width: 100%;
    display: block;
    min-height: 25vh;

  }
  

  .uk-offcanvas-bar .logoBalck {
    margin-bottom: 20px;
  }
  .container-navik{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .uk-nav>li>a {
    padding: 10px 0;
     color: white !important;
    display: flex;
    justify-content: space-between;
  }
  .uk-offcanvas-close {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  .cover-home {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 15.png);
    width: 400px;
    height: 400px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    animation: blabla 9s infinite ease-in;
  }

  @keyframes blabla {
    0% {
      max-width: 100%;
      background-image: url(../../assets/images/Frame\ 16.png);
      width: 400px;
      height: 400px;
      background-position: right;
      background-repeat: no-repeat;
        background-size: cover;
     
 
    }

    25% {
      max-width: 100%;
      background-image: url(../../assets/images/Frame\ 17.png);
      width: 400px;
      height: 400px;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 1;
      z-index: 999;
     
    }

    50% {
      max-width: 100%;
      background-image: url(../../assets/images/Frame\ 18.png);
      width: 400px;
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 1;
      z-index: 999;
      
    }
  }
  .home_menu {
    width: 100%;
    height: 100px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .container_home {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
  }
  
@keyframes identifier {
  0% {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 16.png);
    width: 600px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(1.100);
  }

  25% {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 17.png);
    width: 600px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    z-index: 999;
    transform: scale(1.100);
  }

  50% {
    max-width: 100%;
    background-image: url(../../assets/images/Frame\ 18.png);
    width: 600px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    z-index: 999;
    transform: scale(1.100);
  }
}

}
